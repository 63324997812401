$(function(){

    var closedCardHeight = 114;
    var closedCardWidth = 172;
    var pathWidth = 0;
    var sidePadding = 0;
    var oneExpandedCardWidth = 0;
    sidePadding = 40;
    

    function setSizes(){

        oneExpandedCardWidth = $('body').width() * .9;

        if (oneExpandedCardWidth > 560) {
            oneExpandedCardWidth = 560;
        }

        if ($(window).width() > 999) {
            sidePadding = 250
            closedCardHeight = 170;
            closedCardWidth = 260;
        }
    }

    setSizes();

    window.addEventListener('resize', setSizes);

    function closeStage() {
        $('.career-paths-stage').fadeOut();
        $('.career-path-detail-card').fadeOut();
        $('.career-path-detail').fadeOut();
        $('header').fadeIn();
    }

    function showPath(item) {

        // side scrolling
        var slider = document.querySelector('.career-path-detail__positions--'+item);
        var isDown = false;
        var startX;
        var scrollLeft;
        slider.scrollLeft = 500;

        slider.addEventListener('mousedown', function (e) {
            isDown = true;
            slider.classList.add('active');
            startX = e.pageX - slider.offsetLeft;
            scrollLeft = slider.scrollLeft;
        });

        slider.addEventListener('mouseleave', function () {
            isDown = false;
            slider.classList.remove('active');
        });

        slider.addEventListener('mouseup', function () {
            isDown = false;
            slider.classList.remove('active');
        });

        slider.addEventListener('mousemove', function (e) {
            if (!isDown) return;
            e.preventDefault();
            var x = e.pageX - slider.offsetLeft;
            var walk = (x - startX) * 1.5; //scroll-fast
            slider.scrollLeft = scrollLeft - walk;
        });

    
        var childCount = $('#'+item).find('.career-path-detail__positions-inner').children().length + 1;
        var cardMargins = 40 * childCount;
        pathWidth = (childCount * closedCardWidth) + cardMargins + oneExpandedCardWidth + sidePadding;
        $('.career-path-detail__positions-inner').css('width',pathWidth+'px');
        $('header').fadeOut();
        $('.career-path-detail-card').fadeIn(600);
        $('.career-paths-stage').fadeIn(600);
        $('#'+item+' .career-path-detail-card__group').css({opacity: 0});
        $('#'+item).fadeIn(600, function(){
            $('#'+item+' .career-path-detail-card__group').each(function(index){
                $(this).delay(400*index).animate({opacity: 1}, 500);
            });
        });
        $('.career-path-detail__positions').animate({
            scrollLeft: 250 + 'px'
        }, 800);
    }

    function showLargeCard(source,target) {
        // Clean up the stage
        closeLargeCardsQuickly();
        $('.career-paths-stage__close').fadeOut();

        // Clean up the card
        $('#'+target + ' .career-path-detail-card__copy > *').css('opacity', 0);
        $('#'+target + ' .career-path-detail-card__image').css('opacity', 0);
        $('#'+target + ' .career-path-detail-card__close').css('opacity', 0);
        $('#'+target + ' .career-path-detail-card__actions').css('opacity', 0);
        $('#'+target).closest('.career-path-detail-card').addClass('career-path-detail-card--active');

        $('#'+target).fadeIn(function(){

        // If there are two cards in a group, hide the other one.
        $('#'+target).closest('.career-path-detail-card').siblings().hide(0);

        $('#'+target).css({height: 'auto', width: 'auto'});
        $('#'+target).closest('.career-path-detail-card')
            .animate({
                padding: 0,
                borderColor: 'white',
                color: '#14385C'
                }, 0)
            .animate({
                height: '90%',
                maxHeight: '660px',
                maxWidth: '560px',
                width: '90vw',
            }, 300, function(){
                $('#'+target + '.career-path-detail-card__content').animate({opacity: 1}, 100);
                $('#'+target + ' .career-path-detail-card__copy > h3').animate({opacity: 1}, 300);
                $('#'+target + ' .career-path-detail-card__copy > *').delay(200).animate({opacity: 1}, 300);
                $('#'+target + ' .career-path-detail-card__image').delay(400).animate({opacity: 1}, 300);
                $('#'+target + ' .career-path-detail-card__close').delay(600).animate({opacity: 1}, 300);
                $('#'+target + ' .career-path-detail-card__actions').delay(800).animate({opacity: 1}, 300);
            });
        });

        // center the card
        var cardIndex = $('#'+target).closest('.career-path-detail-card__group').index() + 1;
        // var windowWidth = $(window).width();
        var cardMargins = 35 * cardIndex;
        // var offset =  (closedCardWidth * cardIndex) + cardMargins - (oneExpandedCardWidth / 2) + sidePadding;
        var offset =  (closedCardWidth * cardIndex) + cardMargins + (sidePadding / 2);
        $('.career-path-detail__positions').animate({
            scrollLeft: offset + 'px'
        }, 800);
    }

    function closeLargeCards(target) {
        $('#'+target + ' .career-path-detail-card__actions').animate({opacity: 0}, 300);
        $('#'+target + ' .career-path-detail-card__image').delay(100).animate({opacity: 0}, 150);
        $('#'+target + ' .career-path-detail-card__close').delay(100).animate({opacity: 0}, 150);
        $('#'+target + ' .career-path-detail-card__content').delay(300).animate({opacity: 0}, 150, function(){
            $('#'+target).animate({width: closedCardWidth + 'px', height: closedCardHeight + 'px' }, 300, function(){
                $('.career-path-detail-card').fadeIn(150);
                $('.career-paths-stage__close').fadeIn(150);
                $('.career-path-detail-card__link').fadeIn(150).css({display: 'flex'});
            });
            $('.career-path-detail-card').removeClass('career-path-detail-card--active');
        });
    }

    function closeLargeCardsQuickly() {
        $('.career-path-detail-card').css({width: closedCardWidth + 'px', height: closedCardHeight + 'px'});
        $('.career-path-detail-card').show();
        $('.career-path-detail-card__link').show().css({display: 'flex'});
        $('.career-paths-stage__close').fadeIn();
        $('.career-path-detail-card__content').css({opacity: 0});
        $('.career-path-detail-card').removeClass('career-path-detail-card--active');
    }

    $('.career-paths-stage__close').on('click', function(e){
        e.preventDefault();
        closeStage();
        return false;
    });

    $('.career-path-detail-card__close').on('click', function(e){
        e.preventDefault();
        closeLargeCards($(this).attr('rel'));
        return false;
    });

    $('.career-paths__item-link').on('click', function(e){
        e.preventDefault();
        showPath($(this).attr('rel'));
        return false;
    });

    $('.career-path-detail-card__link').on('click', function(e){
        e.preventDefault();
        showLargeCard($(this), $(this).attr('rel'));
        return false;
    });

    $('.career-path-detail-card__prev').on('click', function(e){
        e.preventDefault();
        $(this).closest('.career-path-detail-card__group').prev('.career-path-detail-card__group').find('.career-path-detail-card:first-of-type .career-path-detail-card__link').click();
        return false;
    });

    $('.career-path-detail-card__next').on('click', function(e){
        e.preventDefault();
        $(this).closest('.career-path-detail-card__group').next('.career-path-detail-card__group').find('.career-path-detail-card:first-of-type .career-path-detail-card__link').click();
        return false;
    });

    $('.career-path-detail-card__actions-apply-btn').on('click', function (){
        closeStage();
            $('html, body').animate({
                scrollTop: $("#positions").offset().top
            }, 2000);
    });
});