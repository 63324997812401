if ($('#intake-form').length >= 1) {
    var $form = $('#intake-form');
    var $formStatusHolder = $('.js-form-status-holder');
    var continueToSuccess = false;
    var manualSave = false;
    // var $form.valid=true;

    $form.autoSaveForm();

    // The following triggers confirm to the beforeSend, error and success ajax callbacks.
    $form.on('beforeSave.autoSaveForm', function (ev, $form, xhr) {
        // called before saving the form
        // here you can return false if the form shouldn't be saved
        // eg. because of validation errors.
        // if (!$form.valid()) {
        //     return false;
        // }

            // Let the user know we are saving
            $formStatusHolder.fadeIn(300).html('Saving...');
    });

    $form.on('saveError.autoSaveForm', function (ev, $form, jqXHR, textStatus, errorThrown) {
        // The saving failed so tell the user
        $formStatusHolder.html('Saving failed! Please retry later.');
    });
    $form.on('saveSuccess.autoSaveForm', function (ev, $form, data, textStatus, jqXHR) {
        // Now show the user we saved and when we did

            $formStatusHolder.html('Saved').delay(1000).fadeOut(300);

        if (manualSave==true) {

            $formStatusHolder.fadeOut();
            $('html').append('<div class="intake-form-dialog"><h1>Saved!</h1><p>You may contiue working, or come back to this page to make updates at a later time. When you are done, please submit the form to our team using the button at the bottom of the page.</p><a class="intake-form-dialog-close__btn btn btn-lg btn-success" href="#">Got it, thanks!</a></div>');
            manualSave=false;
        }

        if (continueToSuccess==true) {

            var href = location.href;
            var id = href.match(/([^\/]*)\/*$/)[1];
            window.location = '/intake-form-submit?id=' + id;

        }
    });


    // manual save
    $('.js-intake-form-save').click(function(){
        $form.trigger('save.autoSaveForm');
        manualSave = true;

        return false;
    });

    // close dialog
    $(document).on('click', '.intake-form-dialog-close__btn', function() {
        console.log('closing');
        $('.intake-form-dialog').remove();
        return false;
    });

    // send to plants
    $('.js-intake-form-send').click(function(){

        $('.js-intake-form__submitted').val('Yes');
        $form.trigger('save.autoSaveForm');
        continueToSuccess = true;

        return false;

    });

    $('.intake-form__checkbox--none').change(function(){
        if ($(this).is(':checked')){
            console.log('checked');
            $(this).parents('.intake-form__form-group').find('input[type="checkbox"]').prop('checked', false);
            $(this).parents('.intake-form__form-group').find('input[type="radio"]').prop('checked', false);
            $(this).prop('checked', true);
        }
        else {
            console.log('unchecked');
        }
    });



    // budget question
    $('.intake-form__label-question').click(function(){

        $('html').append('<div class="intake-form-dialog"><p>When you work with Plants Creative, your designer’s number one goal will be to create your perfect space. However, the very last thing we want to do is create a space that is not within your budget. Our mission is to bring your landscape vision to life, and providing us with a financial guideline helps us sort through a large number of options for your project. If you need help in determining a budget, please select the “I’m Not Sure” option and our designer will help guide you based on the information you’ve included in your questionnaire.</p><a class="intake-form-dialog-close__btn btn btn-lg btn-success" href="#">Got it, thanks!</a></div>');
        return false;
    });
}