window.onload = function() {

    if($('.careers__banner__heading').length) {

        const wordWrap = document.querySelector('.careers__banner__heading');

        wordWrap.classList.remove('initial');

        const letters = document.querySelectorAll('.letter-span.hidden');

        let timer = 0;

        const typeOnce = function(list) {

            Array.from(list).forEach(char => {

                setTimeout(function() {
                    char.classList.remove('hidden');
                }, timer);

                timer += ((Math.random() * 200) + 150);
            });
        }

        typeOnce(letters);

        // IMAGE OPACITY
        const img = document.querySelector('.careers__banner__img');
        setTimeout(() => (img.classList.remove('hidden')), 700);

        const flowerImg = document.querySelector('.intro__img--flower');
        setTimeout(() => (flowerImg.classList.remove('hidden')), 3000);

        // SECOND TYPE ANIMATION -- REPEATING

        setTimeout(function() {

            const word1 = document.querySelector('.repeat--line1');
            const firstInner = word1.innerText.split('').map((char) => `<span class="letter-span hidden">${char}</span>`).join('');
            word1.innerHTML = firstInner;
            const word2 = document.querySelector('.repeat--line2');
            const secondInner = word2.innerText.split('').map((char) => `<span class="letter-span hidden">${char}</span>`).join('');
            word2.innerHTML = secondInner;
            const word3 = document.querySelector('.repeat--line3');
            const thirdInner = word3.innerText.split('').map((char) => `<span class="letter-span hidden">${char}</span>`).join('');
            word3.innerHTML = thirdInner;
            const words = [word1, word2, word3];

            document.querySelector('.careers__banner__h1--small.typewrite--repeat').classList.remove('hidden');

            const TextRepeat = function(words) {
                this.toRotate = words;
                this.loopNum = 0;
                this.currentSubIndex = 0
                this.type();
                this.deleting = false;
            }

            TextRepeat.prototype.type = function() {
                let i = this.loopNum % this.toRotate.length;
                let chars = Array.from(this.toRotate[i].children);

                let that = this;
                let delta = 200 - Math.random() * 10;

                if (!this.deleting && this.currentSubIndex === chars.length - 1) {
                    chars[this.currentSubIndex].classList.remove('hidden');
                    delta = 1800;
                    this.currentSubIndex = 0;
                    this.deleting = true;
                } else if (!this.deleting && this.currentSubIndex < chars.length - 1) {
                    chars[this.currentSubIndex].classList.remove('hidden');
                    this.currentSubIndex ++;
                } else if (this.deleting && this.currentSubIndex < chars.length - 1) {
                    chars[this.currentSubIndex].classList.add('hidden');
                    this.currentSubIndex ++;
                } else {
                    chars[this.currentSubIndex].classList.add('hidden');
                    this.loopNum ++;
                    delta = 1000;
                    this.currentSubIndex = 0;
                    this.deleting = false;
                }

                setTimeout(function() {
                    that.type();
                }, delta);
            }

            new TextRepeat(words);

        }, 2500);

        const btn = document.querySelector('.careers__banner__btn-link');
        setTimeout(() => (btn.classList.remove('hidden')), 4400);
    }

};