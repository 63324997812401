if ($('.form--get-started').length) {
  var subjectValue = '';
  var designPlanValue = '';
  $('.form__form-group--design-package-hidden').slideUp();

  $(document).on('change', 'select#project-type', function(e) {
    subjectValue = this.options[e.target.selectedIndex].text;

    if (
      subjectValue.indexOf('Maintenance') > -1 ||
      subjectValue.indexOf('Cleanup') > -1
    ) {
      $('.form__form-group--design-package select').val('No Package Selected');
      $('.form__form-group--design-package').slideUp(150);
      return;
    }

    $('.form__form-group--design-package').slideDown(150);

    if (
      subjectValue.indexOf('Backyard') > -1 ||
      subjectValue.indexOf('Front Yard') > -1
    ) {
      $('.form__form-group--design-package select').val(
        'Single Space Makeover'
      );
      return;
    }
    if (subjectValue.indexOf('Complete New Landscape') > -1) {
      $('.form__form-group--design-package select').val('Complete Masterplan');
      return;
    }
    if (subjectValue.indexOf('Enhancement') > -1) {
      $('.form__form-group--design-package select').val('Right Plant, Right Place');
      return;
    }
  });

  $(document).on('change', 'select#design-package', function(e) {
    designPlanValue = this.options[e.target.selectedIndex].text;
    subjectValue = $('select#project-type').val();

    if (designPlanValue.indexOf('Right Plant, Right Place') > -1) {
      if (
        subjectValue.indexOf('Backyard') == -1 &&
        subjectValue.indexOf('Front Yard') == -1
      ) {
        $('select#project-type').val('Enhancement');
      }
    }
    if (designPlanValue.indexOf('Complete Masterplan') > -1) {
      $('.form__form-group--subject select').val('Complete New Landscape');
    }
    if (designPlanValue.indexOf('Single Space Makeover') > -1) {
      if (
        subjectValue.indexOf('Backyard') == -1 &&
        subjectValue.indexOf('Front Yard') == -1
      ) {
        $('.form__form-group--subject select').val('General Inquiry');
      }
    }
  });

  $('.form__design-info-button').on('click', function(e) {
    e.preventDefault;
    $('.form__design-info-list').slideToggle(150);
    return false;
  });
}
