$(".form__field").bind("checkval",function(){
  var label = $(this).siblings(".form__label");
  if(this.value !== ""){
    label.addClass("form__label--visible");
  } else {
    label.removeClass("form__label--visible");
  }
}).on("keyup",function(){
  $(this).trigger("checkval");
}).on("focus",function(){
}).trigger("checkval");