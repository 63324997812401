$('.gallery__show-more').click(function(e) {
  e.preventDefault();
  var gallery = $(this)
    .closest('.gallery')
    .find('.gallery__list');
  if ($(this).hasClass('gallery__show-more--open')) {
    gallery.find('.gallery__item--extra').fadeOut(300);
  } else {
    gallery.find('.gallery__item--extra').fadeIn(300);
  }
  $(this).toggleClass('gallery__show-more--open');
});
