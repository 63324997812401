// gallery
if ($('.intake-form-gallery__card').length >= 1) {
    $('.intake-form-gallery__card').on('click', function(){
        var style = $(this).attr('data-style');

        //reset
        $('.intake-form-gallery__indicator').removeClass('intake-form-gallery__indicator--on');
        $('.intake-form-gallery__indicator-off').show();
        $('.intake-form-gallery__indicator-on').hide();

        //set
        $(this).find('.intake-form-gallery__indicator').addClass('intake-form-gallery__indicator--on');
        $(this).find('.intake-form-gallery__indicator-off').fadeOut(100);
        $(this).find('.intake-form-gallery__indicator-on').delay(100).fadeIn(200);
        $('.intake-form__design-style-input').val(style);

        $form.trigger('save.autoSaveForm');

        return false;
    });
}
