$('.carousel__list').slick({
  slide: '.carousel__item',
  prevArrow: '.carousel__arrow--prev',
  nextArrow: '.carousel__arrow--next',
  slidesToShow: 3,
  slidesToScroll: 1,
  infinite: true,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2
      }
    },
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 1
      }
    }
  ]
});

$('.testimonials__list').slick({
  slide: '.testimonials__item',
  arrows: false,
  dots: true,
  appendDots: $('.testimonials__dots')
});