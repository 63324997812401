if ($('.intake-form-scope-selection').length >= 1) {
    console.log('loading project scope');
    var loadedProjectScope = $('#project_scope').val();

    function intakeFormShowPackage(packageVar) {

        $('.intake-form-recommended-design-packages').hide();
        $('.intake-form-recommended-design-packages__card').hide();

        if (packageVar == 'Plantings/Beds') {
            $('.intake-form-recommended-design-packages').show();
            $('#design-package-right-plant-right-place').show();
        } else if (packageVar == 'Single Space')  {
            $('.intake-form-recommended-design-packages').show();
            $('#design-package-single-space-makeover').show();
        } else if (packageVar == 'Full Property') {
            $('.intake-form-recommended-design-packages').show();
            $('#design-package-complete-masterplan').show();
        } else {
            console.log('You’ve requested to the design plan: '+packageVar+', but we don’t have that one.');
        }

    }

    function intakeFormRevealSections(scope) {

        $('.intake-form__assessment-heading').show();
        $('#plantings.intake-form__section').show();
        $('#site-details.intake-form__section').show();
        $('#budget.intake-form__section').show();

        if (scope == 'full') {
            $('#lifestyle.intake-form__section').show();
            $('#design.intake-form__section').show();
            $('#wishlist.intake-form__section').show();
        } else {
            $('#lifestyle.intake-form__section').hide();
            $('#design.intake-form__section').hide();
            $('#wishlist.intake-form__section').hide();
        }

        $('.intake-form__conclusion').show();
    }

    if(loadedProjectScope.length) {
        console.log('loadedProjectScope: '+loadedProjectScope);
        $('.intake-form-scope-selection__card').addClass('deactivated');
        $('.intake-form-scope-selection__card[data-product="'+loadedProjectScope+'"]').addClass('selected').removeClass('deactivated');
        $('.intake-form-scope-selection__card[data-product="'+loadedProjectScope+'"]').find('.intake-form-scope-selection__btn').text('Selected');
        intakeFormShowPackage(loadedProjectScope);

        if(loadedProjectScope=='Plantings/Beds') {
            intakeFormRevealSections();
        } else {
            intakeFormRevealSections('full');
        }
    }

    $('.intake-form-scope-selection__card').on('click', function(e){
        e.preventDefault;

        $('.intake-form-scope-selection__card').removeClass('selected').addClass('deactivated');
        $(this).addClass('selected').removeClass('deactivated');
        $('.intake-form-scope-selection__btn').text('Select');
        $(this).find('.intake-form-scope-selection__btn').text('Selected');
        var newProjectScope = $(this).find('.intake-form-scope-selection__card-heading').text();
        $('#project_scope').val(newProjectScope);
        intakeFormShowPackage(newProjectScope);

        if(newProjectScope=='Plantings/Beds') {
            intakeFormRevealSections();

        } else {
            intakeFormRevealSections('full');
        }

        $form.trigger('save.autoSaveForm');
        return false;
    });
}