if ($(".intake-form__field").length >= 1) {
  console.log('form is starting');
  $(".intake-form__field").bind("checkval",function(){
    var label = $(this).siblings(".intake-form__label");
    if(this.value !== ""){
      label.addClass("intake-form__label--visible");
    } else {
      label.removeClass("intake-form__label--visible");
    }
  }).on("keyup",function(){
    $(this).trigger("checkval");
  }).on("focus",function(){
  }).trigger("checkval");

  $(".intake-form__field").on("focus", function(){
    $(this).parent(".intake-form__form-group").addClass("intake-form__form-group--focus");
  });

  $(".intake-form__field").on("blur", function(){
    $(this).parent(".intake-form__form-group").removeClass("intake-form__form-group--focus");
  });
}
